import React from "react"
import styled, { keyframes } from "styled-components"
import { colors, mq } from "../utils/styles"
import { graphql } from "gatsby"
import { makeLocalePath } from "../utils/localization"
import BackEmbedWebm from "/src/videos/backembed.webm"
import BackEmbedMp4 from "/src/videos/backembed.webm"
import PosterImage from "/src/img/embedposter.jpg"

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Link = styled.a`
  position: relative;
  display: grid;
  min-height: 100px;
  height: 100%;
  background-color: ${colors.background};
  text-decoration: none;
`

const Content = styled.div`
  grid-area: 1 / 1;
  z-index: 1;
  padding: 1rem;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  color: #fff;
`

const Phrase = styled.p`
  margin-bottom: 0;
  font-size: 0.9rem;
  animation: ${appear} 500ms ease;

  ${mq.medium} {
    font-size: 1rem;
  }
`

const Center = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
+
`

const Logo = styled.h2`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  color: ${colors.main};
  animation: ${appear} 500ms ease;

  small {
    font-size: 0.9rem;
  }

  ${mq.medium} {
    font-size: 1.5rem;

    small {
      font-size: 1rem;
    }
  }
`

const Video = styled(props => <video {...props} />)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export default function Embed({ pageContext, data }) {
  let venueUrl
  const mainEmbed = !!pageContext.isGlobalEmbed

  if (mainEmbed) {
    venueUrl = data.site.siteMetadata.siteUrl
  } else {
    let currentVenueData = null
    if (pageContext.currentIsLive) {
      currentVenueData = data.currentLive
    }
    if (pageContext.currentIsClub) {
      currentVenueData = data.currentClub
    }
    if (pageContext.currentIsStudio) {
      currentVenueData = data.currentStudio
    }
    if (pageContext.currentIsRecord) {
      currentVenueData = data.currentRecord
    }

    venueUrl =
      data.site.siteMetadata.siteUrl +
      makeLocalePath(
        currentVenueData?.type,
        currentVenueData?.slug,
        pageContext.locale
      )
  }

  return (
    <Link href={venueUrl} target="_blank">
      {/*<StaticImage*/}
      {/*  style={{*/}
      {/*    gridArea: "1 / 1",*/}
      {/*  }}*/}
      {/*  placeholder="blurred"*/}
      {/*  layout="constrained"*/}
      {/*  quality={92}*/}
      {/*  alt=""*/}
      {/*  height={100}*/}
      {/*  width={1500}*/}
      {/*  src={"../img/fond.jpg"}*/}
      {/*  formats={["auto", "webp"]}*/}
      {/*/>*/}

      <Video
        autoplay="true"
        poster={PosterImage}
        loop="true"
        defaultMuted
        muted="true"
        playsinline="true"
      >
        <source src={BackEmbedWebm} type="video/webm" />
        <source src={BackEmbedMp4} type="video/mp4" />
      </Video>

      {mainEmbed ? (
        <Content>
          <Center>
            <Logo>
              Music <small>in</small> Paris
            </Logo>
            <Phrase>
              La carte interactive des salles, <br />
              clubs, disquaires, studios <br />
              et médias parisiens.
            </Phrase>
          </Center>
        </Content>
      ) : (
        <Content>
          <Center>
            <Phrase>Retrouvez-nous sur</Phrase>
            <Logo>
              Music <small>in</small> Paris
            </Logo>
          </Center>
        </Content>
      )}
    </Link>
  )
}

export const query = graphql`
  query (
    $id: Int
    $currentIsLive: Boolean = false
    $currentIsClub: Boolean = false
    $currentIsStudio: Boolean = false
    $currentIsRecord: Boolean = false
  ) {
    currentLive: wpLive(databaseId: { eq: $id }) @include(if: $currentIsLive) {
      slug
      type: contentTypeName
    }
    currentClub: wpClub(databaseId: { eq: $id }) @include(if: $currentIsClub) {
      slug
      type: contentTypeName
    }
    currentStudio: wpStudio(databaseId: { eq: $id })
      @include(if: $currentIsStudio) {
      slug
      type: contentTypeName
    }
    currentRecord: wpRecord(databaseId: { eq: $id })
      @include(if: $currentIsRecord) {
      slug
      type: contentTypeName
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
